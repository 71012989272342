import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65093a108bd7e600185e78d9"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfHead"} rawKey={"6511ac8515cc038ca22e9a60"}>
				{"window.mainTracker = \"gtag\";\nwindow.gtagTrackerID = \"G-2WL1E4LC2C\";\nfunction gtag() {\n  dataLayer.push(arguments);\n}\n\nfunction loadGTM() {\n  (function (w, d, s, i) {\n    var f = d.getElementsByTagName(s)[0],\n      j = d.createElement(s);\n    j.async = true;\n    j.src = \"https://www.googletagmanager.com/gtag/js?id=\" + i;\n    j.onload = function() {\n      gtag(\"js\", new Date());\n      gtag(\"config\", i, {});\n    }\n    f.parentNode.insertBefore(j, f);\n  })(window, document, \"script\", window.gtagTrackerID);\n}\n\nwindow.onload = function() {\n  if (window.requestIdleCallback) {\n    window.requestIdleCallback(loadGTM, { timeout: 150000 });\n  } else {\n    setTimeout(loadGTM, 2000);\n  }\n}\n"}
			</script>
		</RawHtml>
	</Theme>;
});